import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { LoadingBar } from 'components/atoms';
import { BarIndicatorWithLabels } from 'components/molecules';

import { selectConfig, selectIsAdvanced } from 'features/global/selectors';

import { getElementByKey } from 'utils';

const ResourcingOutlookIndicators = ({ data, level }) => {
	const configData = useSelector(selectConfig);
	const isAdvancedMode = useSelector(selectIsAdvanced);

	const [maxValue, setMaxValue] = useState(0);

	const currentYear = configData.CURRENT_YEAR;

	const [annualIndicators, cumulativeIndicators] = useMemo(() => {
		const needBasedPlan = getElementByKey(data, 'need_based_plan', []);
		const implementationPlan = getElementByKey(data, 'implementation_plan', []);
		const directedContribution = getElementByKey(data, 'directed_contribution');
		const multilateralContribution = getElementByKey(
			data,
			'multilateral_contribution',
		);
		const globalForecast = getElementByKey(data, 'global_forecast', []);
		const operationalRequirements = getElementByKey(
			data,
			'operational_requirements',
			[],
		);
		const expenditures = getElementByKey(data, 'expenditures', []);

		let annualIndicators = [];
		let cumulativeIndicators = [];

		if (data) {
			const annualIndicatorsOrder = [
				'Projected op. requirements',
				'Approved country portfolio needs',
				'Updated implementation plan',
				'Global forecast',
				'Confirmed contribution',
				'Expenditures',
			];

			const extraAnnualIndicators = [
				{ value: implementationPlan, label: 'Updated implementation plan' },
				{ value: expenditures, label: 'Expenditures' },
			];

			annualIndicators = [
				{ value: operationalRequirements, label: 'Projected op. requirements' },
				{ value: needBasedPlan, label: 'Approved country portfolio needs' },
				{
					value: directedContribution + multilateralContribution,
					label: 'Confirmed contribution',
					isMultiple: true,
					withTooltip: !!directedContribution || !!multilateralContribution,

					values: [
						{
							value: directedContribution || 0,
							label: 'Directed',
						},
						{
							value: multilateralContribution || 0,
							label: 'Flexible',
						},
					],
				},
			];

			if (isAdvancedMode) annualIndicators.push(...extraAnnualIndicators);

			if (level === 'global') {
				annualIndicators.push({
					value: globalForecast,
					label: 'Global forecast',
				});
			}

			const annualCurrentValues = annualIndicators.map((item) => {
				if (Array.isArray(item.value)) {
					const currentData = item.value.find(
						(el) => el.year === Number(currentYear),
					);

					if (currentData && currentData.value) return currentData.value;

					return 0;
				}

				return item.value || 0;
			});

			const annualMaxValue = Math.max(...annualCurrentValues);
			annualIndicators = annualIndicators.sort(
				(a, b) =>
					annualIndicatorsOrder.indexOf(a.label) -
					annualIndicatorsOrder.indexOf(b.label),
			);

			// cumulative indicators
			const availableResources = getElementByKey(data, 'available_resources');
			const unspentBalance = getElementByKey(data, 'unspent_balance');
			const outstandingAdvances = getElementByKey(
				data,
				'outstanding_advances',
				[],
			);
			const totalOutstandingAdvances = outstandingAdvances.reduce(
				(a, b) => a + (b.value || 0),
				0,
			);

			const indicatorsOrder = [
				'Available resources',
				'Unprogrammed budget',
				'Unspent balance',
				'Outstanding advances',
			];

			if (isAdvancedMode)
				cumulativeIndicators = [
					{ value: availableResources, label: 'Available resources' },
					{
						value: unspentBalance?.unspent || 0,
						label: 'Unspent balance',
						isMultiple: true,
						withTooltip: unspentBalance?.unspent !== 0,
						values: [
							{ value: unspentBalance?.committed || 0, label: 'Committed' },
							{ value: unspentBalance?.uncommitted || 0, label: 'Uncommitted' },
							{
								value: unspentBalance?.unprogrammed || 0,
								label: 'Unprogrammed',
							},
						],
					},
				];

			if (level === 'country' && isAdvancedMode) {
				cumulativeIndicators.push({
					value: totalOutstandingAdvances,
					label: 'Outstanding advances',
					isMultiple: true,
					withTooltip: totalOutstandingAdvances !== 0,
					values: outstandingAdvances,
				});
			}

			const cumulativeCurrentValues = cumulativeIndicators.map((item) => {
				if (Array.isArray(item.value)) {
					const currentData = item.value.find(
						(el) => el.year === Number(currentYear),
					);

					if (currentData && currentData.value) return currentData.value;

					return 0;
				}

				return item.value || 0;
			});

			const cumulativeMaxValue = Math.max(...cumulativeCurrentValues);
			setMaxValue(
				annualMaxValue > cumulativeMaxValue
					? annualMaxValue
					: cumulativeMaxValue,
			);

			cumulativeIndicators = cumulativeIndicators.sort(
				(a, b) =>
					indicatorsOrder.indexOf(a.label) - indicatorsOrder.indexOf(b.label),
			);

			return [annualIndicators, cumulativeIndicators];
		}

		return [annualIndicators, cumulativeIndicators];
	}, [data, currentYear]);

	const renderIndicator = (data) => {
		let indicator = data;
		let showExtraData = false;

		if (Array.isArray(data.value)) {
			const currentData = data.value.find(
				(item) => item.year === Number(currentYear),
			);
			indicator = { value: 'N/A', label: data.label };
			if (currentData)
				indicator = { value: currentData.value, label: data.label };

			if (data.value.length > 1) showExtraData = true;
		}

		const baseProps = {
			value: indicator.value ? indicator.value : 0,
			label: indicator.label,
			length:
				indicator.value && maxValue ? (indicator.value / maxValue) * 100 : 0,
			truncateWidth: 175,
		};

		return indicator.isMultiple ? (
			<BarIndicatorWithLabels
				{...baseProps}
				key={`bar-indicator-${indicator.label}-${indicator.value}`}
				isMultiple={indicator.isMultiple}
				withTooltip={indicator.withTooltip}
				withTooltipLegend
				multipleValues={indicator.values.map(({ value, label }) => ({
					label,
					value,
					length: value ? (value / maxValue) * 100 : 0,
				}))}
			/>
		) : (
			<BarIndicatorWithLabels
				{...baseProps}
				key={`bar-indicator-${indicator.label}-${indicator.value}`}
				extraData={showExtraData ? data.value : null}
			/>
		);
	};

	return (
		<div className="indicators__container">
			<div className="indicators-annual">
				<div className="indicators-row__title__container">
					<h4 className="indicators-row__title">
						Annual data {currentYear ? `(${currentYear})` : ''}
					</h4>
					<p className="indicators-row__subtitle">In USD</p>
				</div>
				{currentYear ? annualIndicators.map(renderIndicator) : <LoadingBar />}
			</div>
			{isAdvancedMode && (
				<div className="indicators-cumulative">
					<div className="indicators-row__title__container">
						<h4 className="indicators-row__title">Cumulative data</h4>
						<p className="indicators-row__subtitle">
							In USD and includes carry over from previous year
						</p>
					</div>
					{cumulativeIndicators.map(renderIndicator)}
				</div>
			)}
		</div>
	);
};

export default ResourcingOutlookIndicators;
