import React from 'react';
import { useSelector } from 'react-redux';

import { useRegionalBureaus } from 'api';

import { Legend, InfoSource } from 'components/atoms';
import { DoughnutChart } from 'components/molecules';
import { ChartIndicatorsPanel } from 'components/templates';

import { selectCurrentYear } from 'features/global/selectors';

import { getHumanNumberFormat, getElementByKey, getPercents } from 'utils';

const Chart = ({ indicators = [], title, total, chartInnerLines }) => {
	const labelMap = {};
	const lines = indicators.map(({ regional_bureau: rb, value }) => {
		labelMap[rb] = {
			label: rb,
			percents: `${getPercents(value, total)}%`,
			value: getHumanNumberFormat(value),
		};
		return rb;
	});

	return (
		<div className="regional-bureaus-panel-chart">
			<span className="title">{title}</span>
			<div className="title__subtitle">In USD</div>
			<DoughnutChart
				data={indicators.map(({ regional_bureau: rb, value }) => ({
					x: rb,
					y: value,
				}))}
				bottomTextLines={chartInnerLines}
				total={total}
				withLabel={false}
				colorScale={[
					'#003f76',
					'#005f9b',
					'#0780c0',
					'#4aa1e2',
					'#73c3fe',
					'#9deaff',
				]}
				width={270}
				height={270}
				innerRadius={65}
			/>
			<Legend
				colorScale={[
					'#003f76',
					'#005f9b',
					'#0780c0',
					'#4aa1e2',
					'#73c3fe',
					'#9deaff',
				]}
				labelMap={labelMap}
				lines={lines}
				separated
			/>
		</div>
	);
};

export const PANEL_KEY = 'regional-bureaux';

const RegionalBureausPanel = () => {
	const { data, isLoading } = useRegionalBureaus({
		queryKey: [PANEL_KEY],
	});

	const year = useSelector(selectCurrentYear);

	const infoData = getElementByKey(data, 'info');

	return (
		<ChartIndicatorsPanel
			title={year ? `Regional Bureaux (${year})` : 'Regional Bureaux'}
			indicatorsOnly
			allowFullExcelExport={false}
			panelKey={PANEL_KEY}
			className="regional-bureaus-panel"
			noData={!data}
			loading={isLoading}
			indicatorsComponent={
				<div className="regional-bureaus-panel-charts__container">
					<Chart
						title="Approved country portfolio needs"
						total={data && data.needs_based_plan_total}
						chartInnerLines={['APPROVED', 'NEEDS-BASED', 'PLAN']}
						indicators={data && data.needs_based_plan}
					/>
					<Chart
						title="Updated implementation plan"
						total={data && data.implementation_plan_total}
						chartInnerLines={['UPDATED', 'IMPLEMENTATION', 'PLAN']}
						indicators={data && data.implementation_plan}
					/>
					<Chart
						title="Expenditures"
						total={data && data.expenditures_total}
						chartInnerLines={['EXPENDITURES']}
						indicators={data && data.expenditures}
					/>
				</div>
			}
			infoText={<InfoSource text={infoData ? infoData.text : null} />}
			infoTitle={infoData ? infoData.title : null}
		/>
	);
};

export default RegionalBureausPanel;
