import React, { useMemo } from 'react';

import BarIndicatorColumn from './BarIndicatorColumn';

const FoodDistributionIndicators = ({ data }) => {
	const {
		needs_based_plan: needsBasedPlan = [],
		implementation_plan: implementationPlan = [],
		actual_distribution: actualDistribution = [],
		year,
	} = data || {};

	let currentNBPTotal = 0;
	let currentImplementationTotal = 0;
	let currentActualTotal = 0;

	const currentNBP = needsBasedPlan.find(
		(item) => item.year === year.toString(),
	);
	const currentImplementation = implementationPlan.find(
		(item) => item.year === year.toString(),
	);
	const currentActual = actualDistribution.find(
		(item) => item.year === year.toString(),
	);

	if (currentNBP) currentNBPTotal = currentNBP.total;
	if (currentImplementation)
		currentImplementationTotal = currentImplementation.total;
	if (currentActual) currentActualTotal = currentActual.total;

	const maxValue = useMemo(
		() =>
			Math.max(currentNBPTotal, currentImplementationTotal, currentActualTotal),
		[data],
	);

	return (
		<div>
			<div className="title__subtitle">In metric tons</div>
			<BarIndicatorColumn
				label="Approved country portfolio needs"
				value={currentNBPTotal}
				multipleValues={needsBasedPlan}
				length={
					maxValue > 0 && currentNBPTotal > 0
						? (currentNBPTotal / maxValue) * 100
						: 0
				}
				indicatorLabelAdditionSymbol="mt"
			/>
			<BarIndicatorColumn
				label="Updated implementation plan"
				value={currentImplementationTotal}
				multipleValues={implementationPlan}
				length={
					maxValue > 0 && currentImplementationTotal > 0
						? (currentImplementationTotal / maxValue) * 100
						: 0
				}
				indicatorLabelAdditionSymbol="mt"
			/>
			<BarIndicatorColumn
				label="Actual distribution"
				value={currentActualTotal}
				multipleValues={actualDistribution}
				length={
					maxValue > 0 && currentActualTotal > 0
						? (currentActualTotal / maxValue) * 100
						: 0
				}
				indicatorLabelAdditionSymbol="mt"
				color="#73c3fe"
			/>
		</div>
	);
};

export default FoodDistributionIndicators;
