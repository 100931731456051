import React from 'react';
import { useSelector } from 'react-redux';

import { NoData, LoadingBar } from 'components/atoms';

import { getElementByKey, getHumanNumberWithSymbol } from 'utils';

import { selectIsAdvanced } from 'features/global/selectors';

import HorizontalIndicator from '../HorizontalIndicator';

import HorizontalIndicatorMultiple from './HorizontalIndicatorMultiple';

function getTooltipMessage(projectStatus) {
	if (projectStatus === 'erel')
		return (
			<div>
				The Country portfolio needs in this
				<br />
				country currently refers
				<br />
				to the Early Released plan
			</div>
		);
	if (projectStatus === 'isur')
		return (
			<div>
				The Country portfolio needs in this
				<br />
				country currently includes the
				<br />
				Immediate Scale-up Revision (ISUR)
			</div>
		);
	return null;
}

const Funding = ({ funding, isBigNumber }) => {
	const netFundingRequirements = funding
		? funding.net_funding_requirements
		: null;

	const fundingPeriod = funding ? funding.period : null;

	let fundingDescription = `6 months`;
	const fpFrom = getElementByKey(fundingPeriod, 'from');
	const fpTo = getElementByKey(fundingPeriod, 'to');
	if (fpFrom && fpTo)
		fundingDescription = (
			<div>
				<b>{`6 months `}</b>({fpFrom} - {fpTo})
			</div>
		);

	const fundingTotal = funding ? funding.total : null;
	const resourcedValue = funding ? funding.resourced : null;

	const fundingExtraData = [
		{ label: '6-month total requirements', value: fundingTotal },
		{ label: '6-month resourced', value: resourcedValue },
	];

	return (
		<HorizontalIndicator
			value={netFundingRequirements}
			isBigNumber={isBigNumber}
			label="Net funding requirements"
			subLabel={fundingDescription}
			extraIndicators={fundingExtraData}
		/>
	);
};

const YearFunding = ({ funding, isBigNumber }) => {
	const netFundingRequirements = funding
		? funding?.net_funding_requirements
		: null;

	const fundingPeriod = funding ? funding.period : null;

	let fundingDescription = `12 months`;
	const fpFrom = getElementByKey(fundingPeriod, 'from');
	const fpTo = getElementByKey(fundingPeriod, 'to');
	if (fpFrom && fpTo)
		fundingDescription = (
			<div>
				<b>{`12 months `}</b>({fpFrom} - {fpTo})
			</div>
		);

	const fundingTotal = funding ? funding.total : null;
	const resourcedValue = funding ? funding.resourced : null;

	const fundingExtraData = [
		{ label: '12-month total requirements', value: fundingTotal },
		{ label: '12-month resourced', value: resourcedValue },
	];

	return (
		<HorizontalIndicator
			value={netFundingRequirements}
			isBigNumber={isBigNumber}
			label="Net funding requirements"
			subLabel={fundingDescription}
			extraIndicators={fundingExtraData}
		/>
	);
};

const HeaderBottomIndicators = ({ data, loading }) => {
	const isAdvancedMode = useSelector(selectIsAdvanced);

	const operationalRequirements = getElementByKey(
		data,
		'operational_requirements',
	);
	const needBasedPlan = getElementByKey(data, 'need_based_plan');
	const approvedBeneficiaries = getElementByKey(data, 'approved_beneficiaries');
	const year = getElementByKey(data, 'year');
	const projectStatus = getElementByKey(data, 'project_status');

	const funding = getElementByKey(data, 'funding');
	const yearFunding = getElementByKey(data, 'funding_annual');

	const fundingValue = getHumanNumberWithSymbol(
		funding?.net_funding_requirements || 0,
	)[0];
	const yearFundingValue = getHumanNumberWithSymbol(
		yearFunding?.net_funding_requirements || 0,
	)[0];

	const isFundingValueBig =
		isAdvancedMode &&
		(fundingValue >= 100 || (fundingValue >= 10 && yearFundingValue >= 10));
	const isYearFundingValueBig =
		isAdvancedMode &&
		(yearFundingValue >= 100 ||
			(yearFundingValue >= 10 && fundingValue >= 100));

	const fundingTotal = funding ? funding.total : null;

	let content = <NoData />;
	if (needBasedPlan || operationalRequirements || fundingTotal)
		content = (
			<>
				{isAdvancedMode && (
					<HorizontalIndicatorMultiple
						data={needBasedPlan}
						year={year}
						label="country portfolio needs"
						beneficiariesData={approvedBeneficiaries}
						beneficiariesSubLabel="Approved"
						tooltipMessage={getTooltipMessage(projectStatus)}
					/>
				)}
				<HorizontalIndicatorMultiple
					data={operationalRequirements}
					year={year}
					beneficiariesSubLabel="Projected"
					label="operational requirements"
					tooltipLabel="Projected op. requirements"
				/>
				<Funding funding={funding} isBigNumber={isFundingValueBig} />
				<YearFunding
					funding={yearFunding}
					isBigNumber={isYearFundingValueBig}
				/>
			</>
		);
	return (
		<div className="header-bottom-indicators">
			{loading ? (
				<div className="loading-container">
					<LoadingBar isSmall={false} />
				</div>
			) : (
				content
			)}
		</div>
	);
};

export default HeaderBottomIndicators;
